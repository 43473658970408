import React from "react";
import { Link } from "react-router-dom";
import img1 from "../assets/images/headlight.png";

const Headlight = () => {
  return (
    <div className="min-h-screen bg-black text-white font-sans">
      {/* Header Section */}
      <div className="flex flex-col items-center bg-headlight bg-cover bg-no-repeat bg-center h-60 w-screen md:h-[500px]">
        <div className="mt-44 md:mt-96">
          <h1 className="font-extrabold font-head underline decoration-red text-white text-4xl md:text-6xl">
            Headlight Restoration
          </h1>
        </div>
      </div>

      {/* Content Section */}
      <div className="container mx-auto px-4 py-8">
        <div className="flex flex-col md:flex-row gap-8">
          <div className="flex-1">
            <p className="text-lg leading-relaxed mb-4">
              Having safe headlights is crucial if you’re a responsible driver. Weather
              conditions such as rain, fog, or sleet can appear without warning, and
              if your headlights are not in good condition, it can be very dangerous.
            </p>
            <p className="text-lg leading-relaxed mb-4">
              Safe headlights protect your family and other passengers. Headlight
              restoration is a long-lasting, inexpensive service that helps you see
              the road better and avoid dangerous situations.
            </p>
          </div>
          <div className="flex-1 hidden md:block">
            <img
              className="w-full h-auto rounded-lg shadow-lg"
              src={img1}
              alt="Headlight Restoration"
            />
          </div>
        </div>

        {/* Process Section */}
        <div className="mt-12">
          <h3 className="text-2xl font-bold text-red-500 text-center mb-6">
            Length of time required: 2-3 hours
          </h3>
          <div className="grid grid-cols-1 gap-8">
            <div>
              <h3 className="text-xl font-bold mb-4">Headlight Restoration Process</h3>
              <ul className="list-disc list-inside space-y-2">
                <li>
                  We use a series of wet and dry abrasives to first remove oxidation,
                  then commence the refinement process.
                </li>
                <li>
                  Once removed, we restore a like-new shine via a two-stage machine
                  polishing process.
                </li>
                <li>Finally, we remove all polish residues using steam.</li>
                <li>A UV protection layer is applied to guard against future damage.</li>
              </ul>
            </div>
          </div>
        </div>

        {/* Booking Button */}
        <div className="flex justify-center mt-8">
          <Link
            to="/booking"
            className="bg-red text-white px-6 py-2 rounded-md font-semibold hover:bg-red-600 transition duration-300"
          >
            Book Us
          </Link>
        </div>

        {/* Pricing Table */}
        <div className="mt-12 overflow-x-auto">
          <table className="w-full table-auto">
            <thead>
              <tr className="bg-grey">
                <th className="px-4 py-2">Service</th>
                <th className="px-4 py-2">Saloon</th>
                <th className="px-4 py-2">Mid-SUV</th>
                <th className="px-4 py-2">SUV</th>
              </tr>
            </thead>
            <tbody>
              <tr className="bg-grey">
                <td className="px-4 py-2">Headlight Restoration</td>
                <td className="px-4 py-2 text-center">Ksh 4000</td>
                <td className="px-4 py-2 text-center">Ksh 4000</td>
                <td className="px-4 py-2 text-center">Ksh 4000</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default Headlight;
