import React from 'react'
import FAQS from '../components/FAQS'
import Mottoblock from '../components/Mottoblock'
import Servicedeskt from '../components/Servicedeskt'

import Slider from '../components/Slider'
import Statement from '../components/Statement'
import Whychoose from '../components/Whychoose'
import Reviewcard from '../components/Reviewcard'

const Homepage = () => {
  return (
    <div className='h-full w-screen flex flex-col ' >
         <Slider/>
         <Mottoblock/>
         <Statement/>
       
         <Servicedeskt/>
         <Whychoose/>
         <Reviewcard/>
         <FAQS/>

    </div>
  )
}

export default Homepage