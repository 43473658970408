import React from 'react';
import { Link } from 'react-router-dom';
import img1 from '../assets/images/paitcorrectionsmall.jpg';

const PaintRestoration = () => {
  return (
    <div className="min-h-screen bg-black text-white font-sans">
      {/* Header Section */}
      <div className="flex flex-col items-center bg-paintcorrection bg-cover bg-no-repeat bg-center h-60 w-screen md:h-[500px]">
        <div className="mt-36 md:mt-96">
          <h1 className="text-bold font-extrabold font-head underline decoration-red text-white text-4xl md:text-6xl">
            Paint Protection / Correction
          </h1>
        </div>
      </div>

      {/* Content Section */}
      <div className="container mx-auto px-4 py-8">
        <div className="flex flex-col md:flex-row gap-8">
          {/* Text Content */}
          <div className="flex-1">
            <p className="text-lg leading-relaxed mb-4">
              Machine Polishing enhances the appearance of your car's surface color to give it the ultimate shine, perfect for preparation before applying a protective coating to maintain your vehicle's condition.
            </p>
            <p className="text-lg leading-relaxed mb-4">
              Small scratches, discolored lights, or faded finishes can ruin your car's look. Paint correction removes surface defects such as swirl marks, holograms, and minor scratches using advanced machine polishing techniques.
            </p>
          </div>
          {/* Image */}
          <div className="flex-1 hidden md:block">
            <img
              className="w-full h-auto rounded-lg shadow-lg"
              src={img1}
              alt="Paint Correction"
            />
          </div>
        </div>

        {/* Process Section */}
        <div className="mt-12">
          <h3 className="text-2xl font-bold text-red-500 text-center mb-6">
            Length of time required: 1 Day
          </h3>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
            <div>
              <h3 className="text-xl font-bold mb-4">
                Paint Protection / Correction Process
              </h3>
              <ul className="list-disc list-inside space-y-2">
                <li>White snow foam is applied to loosen dirt particles, left briefly, then pressure washed away.</li>
                <li>The vehicle is washed using a 2-bucket system with pH-neutral shampoo to avoid scratches.</li>
                <li>Wheels, arches, and calipers are cleaned using a pH-neutral cleaner.</li>
                <li>Door shuts, petrol cap, arches, and lower sections are thoroughly cleaned.</li>
                <li>The vehicle is dried with plush microfiber towels.</li>
                <li>A clay bar removes bonded surface contaminants from the paintwork.</li>
                <li>An abrasive cutting compound removes swirl marks and light scratches.</li>
                <li>The paint is polished to a high gloss, and IPA removes polishing oils for sealant application.</li>
                <li>Exterior plastics and door trims are dressed, and tires are finished.</li>
              </ul>
            </div>
          </div>
        </div>

        {/* Booking Button */}
        <div className="flex justify-center mt-8">
          <Link
            to="/booking"
            className="bg-red text-white px-6 py-2 rounded-md font-semibold hover:bg-red-600 transition duration-300"
          >
            Book Us
          </Link>
        </div>

        {/* Pricing Table */}
        <div className="mt-12 overflow-x-auto">
          <table className="w-full table-auto">
            <thead>
              <tr className="bg-grey">
                <th className="px-4 py-2"></th>
                <th className="px-4 py-2">Saloon</th>
                <th className="px-4 py-2">Mid-SUV</th>
                <th className="px-4 py-2">SUV</th>
              </tr>
            </thead>
            <tbody>
              <tr className="bg-grey">
                <td className="px-4 py-2">Paint Correction/Buffing</td>
                <td className="px-4 py-2 text-center">Ksh 5000</td>
                <td className="px-4 py-2 text-center">Ksh 6000</td>
                <td className="px-4 py-2 text-center">Ksh 7000</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default PaintRestoration;
