import React from 'react';
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import "./styles.css";
import { Pagination, Navigation } from "swiper";
import img1 from '../assets/images/paitcorrectionsmall.jpg';
import img2 from '../assets/images/IMG-20220823-WA0006.jpg';
import img3 from '../assets/images/engine.jpg';
import img4 from '../assets/images/headlight.png';
import img7 from '../assets/images/watermark.jpg';
import { Link } from 'react-router-dom';

const Servicedeskt = () => {
  return (
    <div className='flex flex-col items-center bg-gradient-to-r from-gray-50 to-gray-100 m-auto w-full min-h-screen py-12 px-4 md:px-8'>
      <div className='text-center mb-8'>
        <h3 className='font-head font-bold text-5xl text-gray-900 underline decoration-red-500 decoration-4'>
          Our Services
        </h3>
        <p className='mt-4 text-sm font-semibold text-gray-600 uppercase tracking-wide'>
  Explore our premium car detailing services designed to keep your vehicle in pristine condition.
</p>
      </div>

      <Swiper
        slidesPerView={1}
        spaceBetween={50}
        loop={true}
        pagination={{
          clickable: true,
          dynamicBullets: true,
        }}
        navigation={{
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        }}
        modules={[Pagination, Navigation]}
        className="mySwiper w-full max-w-6xl"
        breakpoints={{
          768: {
            slidesPerView: 2,
          },
          1024: {
            slidesPerView: 3,
          },
        }}
      >
        {[
          { img: img2, title: 'Interior Steam Detailing', link: '/interior', description: 'An interior Detail is designed to bring the inside of your vehicle up to standard where you enjoy settling into the driver\'s seat and are proud to welcome passengers. It includes vacuuming, detailing around the dashboard, interior parts and trims, and bringing the windows back to sparkling clarity.' },
          { img: img1, title: 'Paint Correction / Protection', link: '/paintcorrection', description: 'Machine Polishing enhances the appearance of your car\'s surface color to give it the ultimate shine, it is perfect for preparation to apply a protective coating to maintain the condition of your vehicle.' },
          { img: img3, title: 'Engine Steam Detailing', link: '/engine', description: 'A clean engine bay gives great peace of mind and is something we recommend you do periodically. It can also save you time and money in the long term as most importantly your engine is protected from rubber and hose corrosion that can potentially be expensive problems to solve down the line.' },
          { img: img4, title: 'Headlight Restoration', link: '/headlight', description: 'Having safe headlights is crucial if you\'re a responsible driver. Weather conditions, such as rain, fog, sleet, etc., can appear without a warning, and in case your headlights are not in good condition, it could be very dangerous.' },
          { img: img7, title: 'Watermark Removal', link: '/watermark', description: 'Unsightly watermarks that mar the beauty of your car\'s exterior. Our team of skilled professionals possesses extensive knowledge in the art of automotive detailing and specializes in removing watermarks with precision and care. We utilize cutting-edge equipment, industry-leading products, and meticulous techniques to restore your vehicle\'s flawless finish.' },
        ].map((service, index) => (
          <SwiperSlide key={index}>
            <Link to={service.link} className='group block bg-white rounded-lg shadow-lg overflow-hidden transform transition-all duration-300 hover:scale-105 hover:shadow-2xl'>
              <div className='relative h-64'>
                <img className='w-full h-full object-cover' src={service.img} alt={service.title} />
                <div className='absolute inset-0 bg-black bg-opacity-0 group-hover:bg-opacity-20 transition-all duration-300'></div>
              </div>
              <div className='p-6'>
                <h3 className='text-2xl font-semibold text-gray-900 mb-2 hover:text-red-500 transition-colors duration-300'>
                  {service.title}
                </h3>
                <p className='text-gray-600 text-base'>{service.description}</p>
              </div>
            </Link>
          </SwiperSlide>
        ))}
      </Swiper>

      {/* Custom Navigation Buttons */}
      <div className="swiper-button-prev swiper-custom-nav"></div>
      <div className="swiper-button-next swiper-custom-nav"></div>
    </div>
  );
};



export default Servicedeskt;