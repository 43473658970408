import StarOutline from "@mui/icons-material/StarOutline";
import React, { useEffect } from "react";
import google from "../assets/images/social.png";
import "./Review.css";
import { setupIntersectionObserver } from "../utils/animation";

const Reviewcard = () => {
  useEffect(() => {
    setupIntersectionObserver();
  }, []);

  return (
    <div className="w-full h-full mb-10 bg-red bg-cover bg-no-repeat flex flex-col gap-6">
      <h3 className="font-head text-center mt-2 font-bold text-4xl underline decoration-white">
        REVIEWS
      </h3>
      <div className="w-full h-full mb-10 flex flex-col gap-6">
        {/* First Review Card */}
        <a href="https://www.google.com/search?q=duckman+auto+detailing">
          <div className="observe review-card w-4/5 h-52 border border-white rounded-md m-auto slide-in-left md:w-2/6 md:h-80 md:m-0 md:ml-60">
            <div className="bg-review2 flex flex-col h-full w-full bg-cover bg-no-repeat bg-center">
              <div className="my-auto mx-4 text-base bg-black bg-opacity-30 font-head text-white flex flex-col review-text">
                <h1 className="text-start">
                  These guys are super experienced. They did a fantastic job in
                  my car, both interior and exterior.
                </h1>
                <div className="flex flex-row">
                  <StarOutline className="text-[#F4B400]" />
                  <StarOutline className="text-[#F4B400]" />
                  <StarOutline className="text-[#F4B400]" />
                  <StarOutline className="text-[#F4B400]" />
                  <StarOutline className="text-[#F4B400]" />
                </div>
                <h1 className="text-center font-pg text-sm">
                  by Hisham Mourtada
                </h1>
              </div>
            </div>
            <div className="flex flex-row justify-between bg-white">
              <h1 className="ml-2 text-black font-pg">4.9/5</h1>
              <img className="h-8 mr-4 google-logo" alt="" src={google} />
            </div>
          </div>
        </a>

        {/* Second Review Card */}
        <a href="https://www.google.com/search?q=duckman+auto+detailing">
          <div className="observe review-card w-4/5 h-52 mt-8 md:mb-8 border border-white rounded-md m-auto slide-in-right md:w-2/6 md:h-80 md:mr-60">
            <div className="bg-review3 flex flex-col h-full w-full bg-cover bg-no-repeat bg-center">
              <div className="my-auto mx-4 text-base bg-black bg-opacity-50 font-head text-white flex flex-col review-text">
                <h1 className="text-start">
                  Wow. If you're yet to work with them, you're missing out. Usiache
                  gari ikae ya 24BC, have them do you a clean job. Utafraia.
                </h1>
                <div className="flex flex-row">
                  <StarOutline className="text-[#F4B400]" />
                  <StarOutline className="text-[#F4B400]" />
                  <StarOutline className="text-[#F4B400]" />
                  <StarOutline className="text-[#F4B400]" />
                  <StarOutline className="text-[#F4B400]" />
                </div>
                <h1 className="text-center font-pg text-sm">
                  by Kerry Ndeta
                </h1>
              </div>
            </div>
            <div className="flex flex-row justify-between bg-white">
              <h1 className="ml-2 text-black font-pg">4.9/5</h1>
              <img className="h-8 mr-4 google-logo" alt="" src={google} />
            </div>
          </div>
        </a>

        {/* Third Review Card */}
        <a href="https://www.google.com/search?q=duckman+auto+detailing">
          <div className="observe review-card w-4/5 h-52 mt-8 border border-white rounded-md m-auto slide-in-left md:w-2/6 md:h-80 md:m-0 md:ml-60">
            <div className="bg-review4 flex flex-col h-full w-full bg-cover bg-no-repeat bg-center">
              <div className="my-auto mx-4 text-base bg-black bg-opacity-30 font-head text-white flex flex-col review-text">
                <h1 className="text-start">
                  They do what they show on Instagram. Excellent service and job.
                  Be blessed.
                </h1>
                <div className="flex flex-row">
                  <StarOutline className="text-[#F4B400]" />
                  <StarOutline className="text-[#F4B400]" />
                  <StarOutline className="text-[#F4B400]" />
                  <StarOutline className="text-[#F4B400]" />
                  <StarOutline className="text-[#F4B400]" />
                </div>
                <h1 className="text-center font-pg text-sm">
                  by Harun Kiarie
                </h1>
              </div>
            </div>
            <div className="flex flex-row justify-between bg-white">
              <h1 className="ml-2 text-black font-pg">4.9/5</h1>
              <img className="h-8 mr-4 google-logo" alt="" src={google} />
            </div>
          </div>
        </a>
      </div>
    </div>
  );
};

export default Reviewcard;