import React from "react";
import { Link } from "react-router-dom";
import img3 from "../assets/images/engine.jpg";

const Engine = () => {
  return (
    <div className="min-h-screen bg-black text-white font-sans">
      {/* Header Section */}
      <div className="flex flex-col items-center bg-engine bg-cover bg-no-repeat bg-center h-60 w-screen md:h-[500px]">
        <div className="mt-36 md:mt-96">
          <h1 className="text-bold font-extrabold font-head underline decoration-red text-white text-4xl md:text-6xl">
            Engine Steam Detailing
          </h1>
        </div>
      </div>

      {/* Content Section */}
      <div className="container mx-auto px-4 py-8">
        <div className="flex flex-col md:flex-row gap-8">
          {/* Text Content */}
          <div className="flex-1">
            <p className="text-lg leading-relaxed mb-4">
              Your engine works hard to get you around but gets dirty over time
              and can even spring a leak.
            </p>
            <p className="text-lg leading-relaxed mb-4">
              A clean engine bay gives great peace of mind and is something we
              recommend you do periodically. It can save you time and money in
              the long term, as most importantly, your engine is protected from
              rubber and hose corrosion that can potentially lead to expensive
              problems.
            </p>
          </div>

          {/* Image */}
          <div className="flex-1 hidden md:block">
            <img
              className="w-full h-auto rounded-lg shadow-lg"
              src={img3}
              alt="Engine Detailing"
            />
          </div>
        </div>

        {/* Process Section */}
        <div className="mt-12">
          <h3 className="text-2xl font-bold text-red-500 text-center mb-6">
            Length of time required: 2-3 hrs
          </h3>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
            <div>
              <h3 className="text-xl font-bold mb-4">Engine Steam Detailing Process</h3>
              <ul className="list-disc list-inside space-y-2">
                <li>Temperature is checked around the engine.</li>
                <li>Sensitive areas (battery, alternator, air filter) are protected with plastic and tape.</li>
                <li>The engine is rinsed using light pressure.</li>
                <li>Steam is used to soften debris and dirt.</li>
                <li>
                  An all-purpose cleaner is applied and worked in with a soft-bristle detailing brush.
                </li>
                <li>The engine is rinsed to lift away dirt.</li>
                <li>Components are carefully dried.</li>
                <li>The engine is run for 1-2 minutes to confirm no issues.</li>
                <li>Dressing is applied to protect and rejuvenate.</li>
              </ul>
            </div>
          </div>
        </div>

        {/* Booking Button */}
        <div className="flex justify-center mt-8">
          <Link
            to="/booking"
            className="bg-red text-white px-6 py-2 rounded-md font-semibold hover:bg-red-600 transition duration-300"
          >
            Book Us
          </Link>
        </div>

        {/* Pricing Table */}
        <div className="mt-12 overflow-x-auto">
          <table className="w-full table-auto">
            <thead>
              <tr className="bg-gray-800 text-white">
                <th className="px-4 py-2"></th>
                <th className="px-4 py-2">Saloon</th>
                <th className="px-4 py-2">Mid-SUV</th>
                <th className="px-4 py-2">SUV</th>
              </tr>
            </thead>
            <tbody>
              <tr className="bg-gray-700 text-white">
                <td className="px-4 py-2">Engine Steam Detailing</td>
                <td className="px-4 py-2 text-center">Ksh 2000</td>
                <td className="px-4 py-2 text-center">Ksh 2000</td>
                <td className="px-4 py-2 text-center">Ksh 2500</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default Engine;
