import AddCircle from '@mui/icons-material/AddCircle';
import React from "react";

const Package = () => {
  return (
    <div className="flex flex-col m-auto w-full h-full">
      <h3 className="font-head m-auto text-center mt-8 font-bold text-4xl underline decoration-red">
        PACKAGES
      </h3>
      <div className="grid md:ml-12 w-full h-full md:grid-cols-2 lg:grid-cols-3 gap-8 mb-8 mt-8 px-4">
        {/* ELITE Package */}
        <div className="mt-1 flex flex-col m-auto bg-white border-4 border-red hover:shadow-2xl hover:border-red transition-all duration-300 rounded-lg w-full h-auto md:w-11/12">
          <div className="mx-auto mt-4 bg-red rounded-lg w-3/4 font-head text-white text-center text-3xl py-2">
            ELITE
          </div>
          <div className="decoration-black underline mx-auto mt-4 text-lg font-head">
            Interior Inclusions
          </div>
          <div className="mx-4 w-4/5 flex flex-col">
            <ul className="text-start marker:text-red list-disc">
              <li>Full vacuum with our PowerBrush system</li>
              <li>Glovebox and storage compartments cleaned</li>
              <li>Air-con vents detailed</li>
              <li>Fabric seats extraction shampooed</li>
              <li>Leather seats deep cleaned</li>
              <li>Headliner cleaned</li>
              <li>Deep steam clean</li>
              <li>Dash UV protected</li>
              <li>Interior glass cleaned</li>
            </ul>
          </div>
          <div className="decoration-black underline mx-auto mt-4 text-lg font-head">
            Exterior Inclusions
          </div>
          <div className="mx-4 w-4/5 flex flex-col">
            <ul className="text-start marker:text-red list-disc">
              <li>Vehicle washed using PH neutral shampoo</li>
              <li>Wheels, arches, and callipers cleaned</li>
              <li>Door shuts, petrol cap, arches cleaned</li>
              <li>Door jambs cleaned</li>
              <li>Vehicle dried using plush microfibre towels</li>
              <li>Wheels glossed</li>
              <li>Exterior glass cleaned</li>
            </ul>
          </div>
          <div className="mb-4 bg-red rounded-lg mx-auto w-4/5 flex flex-col mt-4">
            <div className="m-auto flex gap-2 py-2">
              <ul className="text-center font-head text-lg">
                <li>Saloon -</li>
                <li>Mid-SUV -</li>
                <li>SUV -</li>
              </ul>
              <ul className="text-center font-head text-lg">
                <li>
                  <span className="text-white">KES 5000</span>
                </li>
                <li>
                  <span className="text-white">KES 6600</span>
                </li>
                <li>
                  <span className="text-white">KES 7200</span>
                </li>
              </ul>
            </div>
          </div>
        </div>

        {/* PRESTIGE Package */}
        <div className="mt-1 flex flex-col m-auto bg-white border-4 border-red hover:shadow-2xl hover:border-red transition-all duration-300 rounded-lg w-full h-auto md:w-11/12">
          <div className="mx-auto mt-4 bg-red rounded-lg w-3/4 font-head text-white text-center text-3xl py-2">
            PRESTIGE
          </div>
          <div className="mx-auto mt-4 bg-red rounded-lg w-1/2 font-head text-white text-center text-xl py-1">
            ELITE PACKAGE
          </div>
          <AddCircle sx={{ fontSize: 40 }} className="font m-auto mt-4" />
          <div className="decoration-black underline mx-auto mt-4 text-lg font-head">
            Engine Steam Detailing
          </div>
          <div className="mx-4 w-4/5 flex flex-col">
            <ul className="text-start marker:text-red list-disc">
              <li>Engine rinsed using light pressure</li>
              <li>Steam used to soften debris and dirt</li>
              <li>All-purpose cleaner applied and worked in with a soft bristle detailing brush</li>
              <li>Engine rinsed to lift away dirt</li>
              <li>Components carefully dried</li>
              <li>Dressing applied to protect and rejuvenate</li>
            </ul>
          </div>
          <div className="mb-4 bg-red rounded-lg mx-auto w-4/5 flex flex-col mt-4">
            <div className="m-auto flex gap-2 py-2">
              <ul className="text-center font-head text-lg">
                <li>Saloon -</li>
                <li>Mid-SUV -</li>
                <li>SUV -</li>
              </ul>
              <ul className="text-center font-head text-lg">
                <li>
                  <span className="text-white">KES 7000</span>
                </li>
                <li>
                  <span className="text-white">KES 8600</span>
                </li>
                <li>
                  <span className="text-white">KES 9700</span>
                </li>
              </ul>
            </div>
          </div>
        </div>

        {/* MAJESTIC Package */}
        <div className="mt-1 flex flex-col m-auto bg-white border-4 border-red hover:shadow-2xl hover:border-red transition-all duration-300 rounded-lg w-full h-auto md:w-11/12">
          <div className="mx-auto mt-4 bg-red rounded-lg w-3/4 font-head text-white text-center text-3xl py-2">
            MAJESTIC
          </div>
          <div className="mx-auto mt-4 bg-red rounded-lg w-1/2 font-head text-white text-center text-xl py-1">
            PRESTIGE PACKAGE
          </div>
          <AddCircle sx={{ fontSize: 40 }} className="font m-auto mt-4" />
          <div className="decoration-black underline mx-auto mt-4 text-lg font-head">
            Paint Correction / Buffing
          </div>
          <div className="mx-4 w-4/5 flex flex-col">
            <ul className="text-start marker:text-red list-disc">
              <li>Paintwork treated with a clay bar</li>
              <li>Paint treated with an abrasive cutting compound</li>
              <li>Paint polished to a high gloss</li>
              <li>IPA used to remove oils from polishing phase</li>
              <li>Exterior plastics and door trims dressed</li>
              <li>Tyres dressed</li>
            </ul>
          </div>
          <div className="mb-4 bg-red rounded-lg mx-auto w-4/5 flex flex-col mt-4">
            <div className="m-auto flex gap-2 py-2">
              <ul className="text-center font-head text-lg">
                <li>Saloon -</li>
                <li>Mid-SUV -</li>
                <li>SUV -</li>
              </ul>
              <ul className="text-center font-head text-lg">
                <li>
                  <span className="text-white">KES 12000</span>
                </li>
                <li>
                  <span className="text-white">KES 14600</span>
                </li>
                <li>
                  <span className="text-white">KES 16700</span>
                </li>
              </ul>
            </div>
          </div>
        </div>

        {/* ROYALE Package */}
        <div className="mt-1 flex flex-col m-auto bg-white border-4 border-red hover:shadow-2xl hover:border-red transition-all duration-300 rounded-lg w-full h-auto md:w-11/12">
          <div className="mx-auto mt-4 bg-red rounded-lg w-3/4 font-head text-white text-center text-3xl py-2">
            ROYALE
          </div>
          <div className="mx-auto mt-4 bg-red rounded-lg w-1/2 font-head text-white text-center text-xl py-1">
            MAJESTIC PACKAGE
          </div>
          <AddCircle sx={{ fontSize: 40 }} className="font m-auto mt-4" />
          <div className="decoration-black underline mx-auto mt-4 text-lg font-head">
            Additional Services
          </div>
          <div className="mx-4 w-4/5 flex flex-col">
            <ul className="text-start marker:text-red list-disc">
              <li>Rim and Brake Dust Cleaning</li>
              <li>Watermark Removal</li>
              <li>Headlight Restoration</li>
            </ul>
          </div>
          <div className="mb-4 bg-red rounded-lg mx-auto w-4/5 flex flex-col mt-4">
            <div className="m-auto flex gap-2 py-2">
              <ul className="text-center font-head text-lg">
                <li>Saloon -</li>
                <li>Mid-SUV -</li>
                <li>SUV -</li>
              </ul>
              <ul className="text-center font-head text-lg">
                <li>
                  <span className="text-white">KES 25000</span>
                </li>
                <li>
                  <span className="text-white">KES 28100</span>
                </li>
                <li>
                  <span className="text-white">KES 30200</span>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Package;