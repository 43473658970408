import React from 'react';
import { Link } from 'react-router-dom';

const Mottoblock = () => {
  return (
    <div className='bg-red w-screen h-auto flex flex-col items-center py-8'>
      {/* Heading */}
      <div className='mt-4 text-center'>
        <h3 className='text-white font-Prata font-bold underline decoration-white text-2xl md:text-3xl'>
          DUCKMAN AUTO DETAILING
        </h3>
      </div>

      {/* Description */}
      <div className='w-full max-w-2xl px-4 mt-6'>
        <p className='text-white font-pg text-center text-lg md:text-xl leading-relaxed'>
          The trusted detailing service with attention to detail, cutting-edge technologies, and personal passion to ensure we maintain our 100% customer satisfaction.
        </p>
      </div>

      {/* Book Us Button */}
      <div className='mt-6 mb-4'>
        <Link to='/booking'>
          <div className='bg-white rounded-lg shadow-lg hover:shadow-xl transition-shadow duration-300'>
            <h1 className='text-red-600 px-6 py-2 font-Prata font-semibold text-lg md:text-xl hover:bg-gray-100 rounded-lg transition-colors duration-300'>
              Book Us
            </h1>
          </div>
        </Link>
      </div>
    </div>
  );
};

export default Mottoblock;