import React from "react";
import { Link } from "react-router-dom";
import img3 from "../assets/images/watermark.jpg";

const Watermark = () => {
  return (
    <div className="min-h-screen bg-black text-white font-sans">
      {/* Header Section */}
      <div className="flex flex-col items-center bg-watermark bg-cover bg-no-repeat bg-center h-60 w-screen md:h-[500px]">
        <div className="mt-44 md:mt-96">
          <h1 className="text-bold font-extrabold font-head underline decoration-red text-white text-4xl md:text-6xl">
            Watermark Removal
          </h1>
        </div>
      </div>

      {/* Content Section */}
      <div className="container mx-auto px-4 py-8">
        <div className="flex flex-col md:flex-row gap-8">
          <div className="flex-1">
            <p className="text-lg leading-relaxed mb-4">
              At Duckman Auto Detailing, we understand the frustration of
              unsightly watermarks that mar the beauty of your car's exterior.
              Our team of skilled professionals specializes in removing
              watermarks with precision and care. We utilize cutting-edge
              equipment, industry-leading products, and meticulous techniques to
              restore your vehicle's flawless finish.
            </p>
            <p className="text-lg leading-relaxed mb-4">
              With our thorough process, we ensure your vehicle not only looks
              pristine but is also protected against future watermarks and
              environmental damage.
            </p>
          </div>
          <div className="flex-1 hidden md:block">
            <img
              className="w-full h-auto rounded-lg shadow-lg"
              src={img3}
              alt="Watermark Removal"
            />
          </div>
        </div>

        {/* Services Section */}
        <div className="mt-12">
          <h3 className="text-2xl font-bold text-red-500 text-center mb-6">
            Length of time required: 2-4 hours
          </h3>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
            <div>
              <h3 className="text-xl font-bold mb-4">Prepping the Vehicle</h3>
              <ul className="list-disc list-inside space-y-2">
                <li>The vehicle is thoroughly washed and dried.</li>
                <li>
                  Special attention is given to areas with watermarks to ensure
                  they are clean and ready for treatment.
                </li>
                <li>
                  Techniques like chemical treatments, polishing, or buffing are
                  determined based on the severity of the watermarks.
                </li>
              </ul>
            </div>
            <div>
              <h3 className="text-xl font-bold mb-4">Watermark Removal</h3>
              <ul className="list-disc list-inside space-y-2">
                <li>
                  Precision techniques are applied to remove watermarks without
                  harming the vehicle's surface.
                </li>
                <li>The vehicle is polished to restore its shine.</li>
                <li>
                  A protective coating is applied to guard against future
                  watermarks and enhance durability.
                </li>
              </ul>
            </div>
          </div>
        </div>

        {/* Booking Button */}
        <div className="flex justify-center mt-8">
          <Link
            to="/booking"
            className="bg-red text-white px-6 py-2 rounded-md font-semibold hover:bg-red-600 transition duration-300"
          >
            Book Us
          </Link>
        </div>

        {/* Pricing Table */}
        <div className="mt-12 overflow-x-auto">
          <table className="w-full table-auto">
            <thead>
              <tr className="bg-grey">
                <th className="px-4 py-2">Service</th>
                <th className="px-4 py-2">Saloon</th>
                <th className="px-4 py-2">Mid-SUV</th>
                <th className="px-4 py-2">SUV</th>
              </tr>
            </thead>
            <tbody>
              <tr className="bg-grey">
                <td className="px-4 py-2">Watermark Removal</td>
                <td className="px-4 py-2 text-center">Ksh 5000</td>
                <td className="px-4 py-2 text-center">Ksh 5500</td>
                <td className="px-4 py-2 text-center">Ksh 5500</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default Watermark;
