import React, { useRef, useState } from "react";
import { toast } from 'react-toastify';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import EmailIcon from '@mui/icons-material/Email';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import InstagramIcon from '@mui/icons-material/Instagram';
import TwitterIcon from '@mui/icons-material/Twitter';
import FacebookIcon from '@mui/icons-material/Facebook';
import emailjs from '@emailjs/browser';
import Slider from "../components/Slider";
import sent from '../assets/images/sent1.webp';

const Book = () => {
  const form = useRef();
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);

  const sendEmail = (e) => {
    e.preventDefault();

    const selectedServices = Array.from(
      form.current.elements.categories
    ).reduce((services, checkbox) => {
      if (checkbox.checked) {
        services.push(checkbox.value);
      }
      return services;
    }, []);

    const formData = new FormData(form.current);
    formData.append('services', selectedServices.join(', '));

    const emailData = {
      name: formData.get('name'),
      email: formData.get('email'),
      tel: formData.get('tel'),
      Model: formData.get('Model'),
      categories: selectedServices.join(', '),
      message: formData.get('message'),
      location: formData.get('location'),
    };

    emailjs
      .send('service_zghvd7o', 'template_e7haqof', emailData, '92DMt1KF5hIM7h7ga')
      .then(
        (result) => {
          console.log(result.text);
          setIsFormSubmitted(true);
          toast.success("Booking has been sent successfully!");
        },
        (error) => {
          console.log(error.text);
          toast.error('Failed to submit booking. Please try again.');
        }
      );

    form.current.reset();
  };

  return (
    <div className="flex flex-col w-screen items-center h-full lg:h-full bg-gray-50">
      <Slider />

      <div className="flex flex-col-reverse gap-8 w-screen h-auto lg:flex-row lg:mb-16 lg:px-12">
        {/* Contact Information Section */}
        <div className="flex flex-col w-full h-auto text-base font-pg lg:w-1/2 lg:h-auto md:text-lg bg-white p-8 rounded-lg shadow-lg">
          <div className="flex flex-col items-center">
            <h3 className="font-head text-2xl lg:text-3xl mb-4 underline decoration-red-500 decoration-4">
              Talk to Us
            </h3>
            <p className="w-4/6 text-center lg:text-start text-gray-700">
              At Duckman Auto Detailing, we take pride in each project we undertake. From protection to restoration, you can rest assured that your car is in good hands. Our eco-friendly solutions deliver unbelievable results!
            </p>
          </div>

          <div className="flex flex-col my-6 ml-8 gap-4 text-gray-700 lg:ml-16">
            <div className="flex items-center gap-2">
              <LocationOnIcon className="text-red-500" />
              <span>Nairobi, Kenya</span>
            </div>
            <div className="flex items-center gap-2">
              <WhatsAppIcon className="text-[#075E54]" />
              <span>+254702982035</span>
            </div>
            <div className="flex items-center gap-2">
              <EmailIcon className="text-main" />
              <span>info@duckmanautodetailing.com</span>
            </div>
          </div>

          <div className="flex mt-4 ml-8 mb-4 gap-4 lg:ml-16">
            <a href="https://www.facebook.com/profile.php?id=100086023751331">
              <FacebookIcon className="text-[#4267B2] hover:text-[#3b5998] transition-colors" />
            </a>
            <a href="https://twitter.com/DuckmanAuto">
              <TwitterIcon className="text-[#00acee] hover:text-[#1DA1F2] transition-colors" />
            </a>
            <a href="https://www.instagram.com/duckmanautodetailing">
              <InstagramIcon className="text-[#ee2e2e] hover:text-[#E1306C] transition-colors" />
            </a>
          </div>
        </div>

        {/* Booking Form Section */}
        <div className="flex flex-col items-center w-full h-auto lg:w-1/2 bg-white p-8 rounded-lg shadow-lg">
          <h3 className="font-head text-2xl lg:text-3xl mb-4 underline decoration-red-500 decoration-4">
            Book With Us Today
          </h3>
          {isFormSubmitted ? (
            <div className="w-full h-auto m-auto text-lg font-head flex flex-col gap-4 lg:mt-4 md:text-lg">
              <img className="h-20 w-20 m-auto" src={sent} alt="Sent" />
              <div className="m-auto text-center">
                <p>Thank you for your booking!</p>
                <p>We will contact you shortly.</p>
              </div>
            </div>
          ) : (
            <form
              ref={form}
              onSubmit={sendEmail}
              className="w-full h-auto m-auto text-base font-pg flex flex-col gap-4 lg:mt-4 md:text-lg"
            >
              <input
                required
                type="text"
                placeholder="Your Name"
                name="name"
                className="w-full border-2 border-gray-300 rounded-lg h-12 px-4 outline-none focus:border-red-500 transition-colors"
              />
              <input
                required
                type="email"
                placeholder="Your Email"
                name="email"
                className="w-full border-2 border-gray-300 rounded-lg h-12 px-4 outline-none focus:border-red-500 transition-colors"
              />
              <input
                required
                type="tel"
                placeholder="Your Phone Number"
                name="tel"
                className="w-full border-2 border-gray-300 rounded-lg h-12 px-4 outline-none focus:border-red-500 transition-colors"
              />
              <input
                required
                type="text"
                placeholder="Location"
                name="location"
                className="w-full border-2 border-gray-300 rounded-lg h-12 px-4 outline-none focus:border-red-500 transition-colors"
              />
              <input
                required
                type="text"
                placeholder="Vehicle Model"
                name="Model"
                className="w-full border-2 border-gray-300 rounded-lg h-12 px-4 outline-none focus:border-red-500 transition-colors"
              />
              <div className="w-full">
                <p className="font-medium text-xl font-head mb-2">Select Service(s):</p>
                <div className="flex flex-col gap-2">
                  {[
                    "Interior steam detailing",
                    "Paint Correction / Protection",
                    "Engine Steam Detailing",
                    "Headlight Restoration",
                    "Watermark Removal",
                  ].map((service, index) => (
                    <label key={index} className="flex items-center gap-2">
                      <input
                        type="checkbox"
                        name="categories"
                        value={service.split(" ")[0]}
                        className="w-5 h-5"
                      />
                      <span>{service}</span>
                    </label>
                  ))}
                </div>
              </div>
              <textarea
                name="message"
                placeholder="Additional Information"
                className="w-full border-2 border-gray-300 rounded-lg h-32 px-4 py-2 outline-none focus:border-red-500 transition-colors"
              />
               <button className="ml-4 bg-red text-white text-base font-extrabold w-[100px] h-[40px] lg:ml-2">
              Send{" "}
            </button>
            </form>
          )}
        </div>
      </div>
    </div>
  );
};

export default Book;