import React from "react";
import { Link } from "react-router-dom";
import img3 from '../assets/images/IMG-20220823-WA0006.jpg'



const Interior = () => {
  return (
    <div className="min-h-screen bg-black text-white font-sans">
      <div className="flex flex-col items-center  bg-interior bg-cover bg-no-repeat bg-center  h-60 w-screen md:h-[500px] ">
        <div className="mt-44 md:mt-96">
          <h1 className="text-bold font-extrabold font-head underline decoration-red text-whitee text-4xl md:text-6xl">
            Interior Steam Clean
          </h1>
        </div>
      </div>
      <div className="container mx-auto px-4 py-8">
        <div className="flex flex-col md:flex-row gap-8">
          <div className="flex-1">
            <p className="text-lg leading-relaxed mb-4">
              Saturated dry steam is the way forward, it is a 100% green technology
              that makes sanitisation possible without the use of harsh chemicals,
              using less water and producing less wastewater. Caring for the
              cleanliness of your car’s interior has never been easier!
            </p>
            <p className="text-lg leading-relaxed mb-4">
              We have chemical-free interior detailing available to aid in the
              removal of bacteria and allergens using high pressurised hot steam
              paired with natural, yet relentless, cleaning products – guaranteed to
              make you feel you at ease whilst driving your car that feels like new
              again! It is the perfect solution to kill and remove all bacteria and
              any lingering odours.
            </p>
          </div>
          <div className="flex-1 hidden md:block">
            <img className="w-full h-auto rounded-lg shadow-lg" src={img3} alt="Interior Cleaning" />
          </div>
        </div>

        {/* Services Section */}
        <div className="mt-12">
          <h3 className="text-2xl font-bold text-red-500 text-center mb-6">
            Length of time required 2-4 hours
          </h3>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
            <div>
              <h3 className="text-xl font-bold mb-4">Interior</h3>
              <ul className="list-disc list-inside space-y-2">
                <li>Dry Vaccum</li>
                <li>Shampoo of carpets removing any dirt and stain</li>
                <li>Dashboard, trims and vents steam cleaned</li>
                <li>Contact points such as steering wheel deep cleaned</li>
                <li>Upholstered seats are shampooed and steamed</li>
                <li>Or leather seats are cleaned and conditioned</li>
              </ul>
            </div>
            <div>
              <h3 className="text-xl font-bold mb-4">Exterior</h3>
              <ul className="list-disc list-inside space-y-2">
                <li>Non contact pre wash and rinse to loosen dirt</li>
                <li>Wheels deep cleaned</li>
                <li>Underarches, doors shuts, fuel caps cleaned</li>
                <li>Snowfoam applied with soft brush used to agitate intricate areas</li>
                <li>2 bucket contact wash</li>
                <li>Windows cleaned and polished using microfibre towels</li>
                <li>Tyres Dressed and excess water wiped down</li>
              </ul>
            </div>
          </div>
        </div>

        {/* Booking Button */}
        <div className="flex justify-center mt-8">
          <Link to="/booking" className="bg-red text-white px-6 py-2 rounded-md font-semibold hover:bg-red-600 transition duration-300">
            Book Us
          </Link>
        </div>

        {/* Pricing Table */}
        <div className="mt-12 overflow-x-auto">
          <table className="w-full table-auto">
            <thead>
              <tr className="bg-grey">
                <th className="px-4 py-2">Service</th>
                <th className="px-4 py-2">Saloon</th>
                <th className="px-4 py-2">Mid-SUV</th>
                <th className="px-4 py-2">SUV</th>
              </tr>
            </thead>
            <tbody>
              <tr className="bg-grey">
                <td className="px-4 py-2">Interior Detailing</td>
                <td className="px-4 py-2 text-center">Ksh 5000</td>
                <td className="px-4 py-2 text-center">Ksh 6600</td>
                <td className="px-4 py-2 text-center">Ksh 7200</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default Interior;