import React from 'react';

const PricingTable = () => (
  <table className="w-full font-pg">
    <thead className="font-head">
      <tr>
        <th></th>
        <th className="border border-slate-600 md:text-lg">Saloon</th>
        <th className="border border-slate-600 md:text-lg">Mid-SUV</th>
        <th className="border border-slate-600 md:text-lg">SUV</th>
      </tr>
    </thead>
    <tbody>
      {[
        { service: 'Interior Detailing', saloon: 5000, midSuv: 6600, suv: 7200 },
        { service: 'Engine Steam Detailing', saloon: 2000, midSuv: 2000, suv: 2500 },
        { service: 'Headlight Restoration', saloon: 4000, midSuv: 4000, suv: 4000 },
        { service: 'Paint Correction/Buffing', saloon: 5000, midSuv: 6000, suv: 7000 },
        { service: 'Watermark Removal', saloon: 5000, midSuv: 5500, suv: 5500 },
        { service: 'Rim cleaning /Polishing', saloon: 4000, midSuv: 4000, suv: 4000 },
        { service: 'Full Detailing Job', saloon: 25000, midSuv: 28100, suv: 30200 },
      ].map((row, index) => (
        <tr key={index}>
          <td className="font-head md:text-lg">{row.service}</td>
          <td className="text-center text-base">Ksh {row.saloon}</td>
          <td className="text-center text-base">Ksh {row.midSuv}</td>
          <td className="text-center text-base">Ksh {row.suv}</td>
        </tr>
      ))}
    </tbody>
  </table>
);

const FullDetailingDescription = () => (
  <div className="flex flex-col mx-2 mt-6 md:w-1/2 m-auto">
    <h3 className="text-center text-xl font-head underline decoration-red-500 decoration-4">
      Full Detailing Job
    </h3>
    <p className="font-pg text-lg">
      <span className="font-head">Exterior detailing:</span> The vehicle is washed, clay barred, polished, and waxed. The paint is thoroughly cleaned, restored, and protected.
    </p>
    <p className="font-pg text-lg">
      <span className="font-head">Interior detailing:</span> The interior of the vehicle is thoroughly cleaned, including shampooing the carpets and upholstery, cleaning and conditioning the leather, and detailing the dashboard and other surfaces.
    </p>
    <p className="font-pg text-lg">
      <span className="font-head">Engine detailing:</span> The engine bay is cleaned and dressed to remove grime and dirt.
    </p>
    <p className="font-pg text-lg">
      <span className="font-head">Additional Services:</span> such as wheel and tire cleaning and headlight restoration.
    </p>
  </div>
);

const Pricing = () => (
  <div className="flex flex-col h-full w-screen">
    <div className="mx-2 w-full">
      <PricingTable />
    </div>
    <FullDetailingDescription />
  </div>
);

export default Pricing;